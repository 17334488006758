import React from "react"
import Contacts from "../components/Contacts"
import Footer from "../components/Footer"
import { Link } from "gatsby"
import Head from "../components/Head"
import { Helmet } from "react-helmet"

class Bestellungen extends React.Component {

  disableButton(){
    let submit = document.getElementById("submitBtn");
    submit.style.pointerEvents= "none";
  }
  render() {
    return (
      <div>
        <Head />
        <div className="container">
          <div className="main">
            <div className="header">
              <div className="title">
                <a href="/">
                  <h1> </h1>
                </a>{" "}
              </div>
            </div>
            <div className="content">
              <div className="item">
                <h1>Preise und Versand</h1>
                <br />
                <p>
                  "Katharinas Backrezepte - Band I" kostet 16,00&#8364; zzgl.
                  2,40&#8364; Versand
                  <br />
                  "Katharinas Backrezepte - Band II" <b> ist derzeit leider vergriffen.</b>
                  <br />
                  "Katharinas Kochrezepte" <b> Buch ist derzeit leider vergriffen.</b>
                  <br />
                  "Katharinas Einweckrezepte" kostet 14,00&#8364; zzgl.
                  2,40&#8364; Versand
                </p>
                <p className="shippingInformation">
                  Der Lieferung erfolgt erst nach Zahlungseingang. Bitte
                  überweisen Sie den Rechnungsbetrag vorab auf die angegebene
                  Bankverbindung in ihrer Bestätigungsemail.
                </p>

                <p>
                  Der Versand erfolgt als Büchersendung. Bei der Bestellung
                  mehrerer Bücher setzt sich das Porto abhängig vom Gewicht der
                  Lieferung zusammen. Die Rechnung erhalten Sie mit der
                  Lieferung.
                </p>
                <p>
                  Die Versandkosten beziehen sich auf Lieferungen innerhalb
                  Deutschlands. Sollten Sie eine Auslandslieferung wünschen
                  kontaktieren Sie mich bitte persönlich per Telefon oder Email.{" "}
                  <Link to="/kontakt/">Kontaktdaten</Link>
                </p>
              </div>
              <div className="item">
                <h1>Bestellung</h1>
                <br />
                <p>
                  Vielen Dank für Ihr Interesse an meinen Büchern. Das
                  Bestellformular berechnet Ihnen den Endpreis inkl.
                  Versandkosten.
                </p>
                <p>
                  Meine Bücher sind auch hervorragend als Geschenkidee geeignet.
                </p>
                <p> Es gibt keine Mindestbestellmenge.</p>
              </div>
              <div className="item">
                <h1>Bestellmenge</h1>
                <form id="submitOrder">
                  <table id="table1" border="0" width="100%">
                    <tbody>
                      <tr>
                        <td width="276">
                          Anzahl "Katharinas Backrezepte - Band I":
                        </td>
                        <td>
                          <select
                            size="1"
                            name="Backbuch1"
                            id="bb1"
                            defaultValue="0"
                          >
                            <option>0</option>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <td width="276">Anzahl "Katharinas Einweckrezepte":</td>
                        <td>
                          <select
                            size="1"
                            name="Einweckbuch"
                            id="eb"
                            defaultValue="0"
                          >
                            <option>0</option>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <td width="185">
                          Vor- und Nachname:<font color="#FF0000">*</font>
                        </td>
                        <td>
                          <input
                            type="text"
                            name="Name"
                            size="36"
                            id="name"
                            required="required"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td width="185">
                          Straße und Hausnummer:<font color="#FF0000">*</font>
                        </td>
                        <td>
                          <input
                            type="text"
                            name="Strasse"
                            size="36"
                            id="street"
                            required="required"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td width="185">
                          PLZ und Ort:<font color="#FF0000">*</font>
                        </td>
                        <td>
                          <input
                            type="text"
                            name="Ort"
                            size="36"
                            id="place"
                            required="required"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td width="185">
                          Tel.nr. für evtl. Rückfragen:
                          <font color="#FF0000">*</font>
                        </td>
                        <td>
                          <input
                            type="text"
                            name="Tel"
                            size="36"
                            id="phone"
                            required="required"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td width="185">
                          E-Mail Adresse für Auftragsbestätigung:
                        </td>
                        <td>
                          <input
                            type="text"
                            name="Email"
                            size="36"
                            id="email"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td width="185">&nbsp;</td>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <td width="185" valign="top">
                          Bemerkung
                          <br />
                          zu Ihrer Bestellung:
                        </td>
                        <td>
                          <textarea
                            rows="4"
                            name="Kommentar"
                            cols="28"
                            id="note"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td width="185" valign="top">
                          Aktueller Preis
                        </td>
                        <td>
                          <input
                            type="text"
                            id="price"
                            name="Preis"
                            size="30"
                            value="0.00€"
                            readOnly
                          />
                        </td>
                      </tr>
                      <tr>
                        <td width="185" valign="top">
                          Versandkosten
                        </td>
                        <td>
                          <input
                            type="text"
                            id="shipping"
                            name="Versandkosten"
                            size="30"
                            value="0.00€"
                            readOnly
                          />
                        </td>
                      </tr>
                      <tr>
                        <td width="185" valign="top">
                          Gesamtpreis
                        </td>
                        <td>
                          <input
                            type="text"
                            id="total"
                            name="Gesamtpreis"
                            size="30"
                            value="0.00€"
                            readOnly
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <p>
                    <br />
                    Für größere Bestellungen kontaktieren Sie mich bitte
                    persönlich per Telefon oder Email. <br />
                    <input type="submit" id="submitBtn" onClick={this.disableButton} value="Kostenpflichtig Bestellen" style={{padding:'5px', marginTop: '10px'}} />
                    <br />
                  </p>
                </form>

                <div>
                  <textarea
                    id="status"
                    name="Statusbox"
                    rows="3"
                    cols="50"
                    style={{ display: "none" }}
                    readOnly
                  />
                </div>

                <Helmet>
                  <script type="text/javascript">
                    {`
                  document.getElementById("bb1").addEventListener("change", updatePrice);

                  document.getElementById("eb").addEventListener("change", updatePrice);
                  
                  function updatePrice() {
                    let backBuch1Quantity = parseInt(document.getElementById("bb1").value);
                    let einweckBuchQuantity = parseInt(document.getElementById("eb").value);
                    let backBuch1price = 16.00;
                    let einweckBuch = 14.00;

                    let price = backBuch1price * backBuch1Quantity + einweckBuch * einweckBuchQuantity;
                    let shipping = 0.00;  
                    let shippingWeight = backBuch1Quantity * 300 +  einweckBuchQuantity * 220;
                    
                    if (shippingWeight > 2500) {shipping = 8.00} else
                    if (shippingWeight > 1500 && shippingWeight <= 2500) {shipping = 5.60} else
                    if (shippingWeight > 1000 && shippingWeight <= 1500) {shipping = 5.20} else
                    if (shippingWeight > 500 && shippingWeight <= 1000) {shipping = 2.80} else
                    if (shippingWeight <= 500) {shipping = 2.40}

                    if (backBuch1Quantity === 0 &&
                      einweckBuchQuantity === 0)
                      {
                        shipping = 0.00;
                      }
                  
                    document.getElementById("price").readOnly = false;
                    document.getElementById("price").value = price.toFixed(2) + '€';
                    document.getElementById("price").readOnly = true;
                  
                    document.getElementById("shipping").readOnly = false;
                    document.getElementById("shipping").value = shipping.toFixed(2) + '€';
                    document.getElementById("shipping").readOnly = true;
                  
                    let total = price + shipping;
                  
                    document.getElementById("total").readOnly = false;
                    document.getElementById("total").value = total.toFixed(2) + '€';
                    document.getElementById("total").readOnly = true;

                  }
                  
                  document
                    .getElementById("submitOrder")
                    .addEventListener("submit", submitOrder)
                  function processResponse(response) {
                    if (response.status === 200) {
                      document.getElementById("status").style.display='';
                      document.getElementById("status").style.color='#4A91C3';
                      document.getElementById("status").readOnly = false;
                      document.getElementById("status").value = 'Vielen Dank für Ihre Bestellung! Wir werden diese umgehend bearbeiten und Sie erhalten noch eine Bestätigungsmail an die angegeben Emailaddresse.'
                      document.getElementById("status").readOnly = true;
                      document.getElementById("submitOrder").reset();
                      document.getElementById("price").value = "0.00€";
                      document.getElementById("shipping").value = "0.00€";
                      document.getElementById("total").value = "0.00€";
                    } else {
                      document.getElementById("status").style.display='';
                      document.getElementById("status").readOnly = false;
                      document.getElementById("status").value = 'Etwas ist schief gelaufen, bitte versuchen Sie es später erneut.';
                      document.getElementById("status").readOnly = true;
                    }
                  }
                  function submitOrder(e) {
                    e.preventDefault();
                    // const proxyURL = "https://cors-anywhere.herokuapp.com/";
                    const sendMailToCustomerURL = "https://mailjetsendmail.azurewebsites.net/api/SendMailToCustomer?code=7M0xsnVyJSip6vxTMVZgmBCk0YDePUHymzOP3Ze2crduZHb67rcciQ==";
                    let name = document.getElementById("name").value;
                    let email = document.getElementById("email").value;
                    let note = document.getElementById("note").value;
                    let phone = document.getElementById("phone").value;
                    let place = document.getElementById("place").value;
                    let street = document.getElementById("street").value;
                    let price = document.getElementById("price").value;
                    let shipping = document.getElementById("shipping").value;
                    let total = document.getElementById("total").value;
                    let backBuch1Quantity = document.getElementById("bb1").value;
                    let einweckBuchQuantity = document.getElementById("eb").value;
                  
                    let bankInformationen = 
                    "Bitte überweisen Sie den Rechnungsbetrag vorab auf folgendes Bankkonto:" +
                    "\\n\\n" +
                    "Hermann & Katharina Gabber" +
                    "\\n\\n" +
                    "IBAN DE36 7215 0000 0008 0405 03" +
                    "\\n\\n" +
                    "BIC  BYLADEM1ING" +
                    "\\n\\n" + 
                    "Die Bücher werden nach Zahlungseingang verschickt."

                    let message =
                      "Sehr geehrte/r " +
                      name +
                      "!" +
                      "\\n\\n" +
                      "Vielen Dank für Ihre Bestellung bei Katharinas Rezepte. " +
                      "Nachfolgend haben wir zur Kontrolle die von Ihnen aufgegebene Bestellung aufgelistet." +
                      "\\n\\n" +
                      bankInformationen +
                      "\\n\\n" +
                      "Lieferadresse:" +
                      "\\n\\n" +
                      place +
                      "\\n\\n" +
                      street +
                      "\\n\\n" +
                      "Tel.: \\n\\n" +
                      phone +
                      "\\n\\n" +
                      "Produkte: \\n\\n"
                  
                    if (backBuch1Quantity > 0) {
                      message = message + backBuch1Quantity + "x Backbuch 1" + "\\n\\n"
                    }
                    if (einweckBuchQuantity > 0) {
                      message = message + einweckBuchQuantity + "x Einweckbuch" + "\\n\\n"
                    }
                  
                    message =
                      message +
                      "Preis: " +
                      price +
                      "\\n\\n" +
                      "Versandkosten: " +
                      shipping +
                      "\\n\\n" +
                      "Gesamtkosten: " +
                      total +
                      "\\n\\n" +
                      "Anmerkungen: \\n\\n" +
                      note +
                      "\\n\\n"
                  
                    message =
                      message +
                      "Bei Fragen, Unklarheiten oder Problemen zu Ihrer Bestellung schreiben Sie bitte eine email an hermann.katharinagabber@t-online.de." +
                      "\\n\\n" +
                      "Mit freundlichen Grüßen,\\n\\n" +
                      "Katharina Gabber"
                  
                    if (
                      document.getElementById("bb1").value > 0 ||
                      document.getElementById("eb").value > 0
                    ) {
                      // fetch(proxyURL + sendMailToCustomerURL, {
                      fetch(sendMailToCustomerURL, {
                        method: "POST",
                        headers: {
                          Accept: "application/json, text/plain, */*",
                          "Content-type": "application/json",
                        },
                        body: JSON.stringify({
                          name: name,
                          email: email,
                          message: message,
                        }),
                      }).then(res => processResponse(res))
                      
                      // fetch(proxyURL + sendMailToCustomerURL, {
                      fetch(sendMailToCustomerURL, {
                        method: "POST",
                        headers: {
                          Accept: "application/json, text/plain, */*",
                          "Content-type": "application/json",
                        },
                        body: JSON.stringify({
                          name: name,
                          email: "Hermann.katharinagabber@t-online.de",
                          message: message,
                        }),
                      }).then(res => processResponse(res))

                    } else {
                      document.getElementById("status").style.display='';
                      document.getElementById("status").style.color='red';
                      document.getElementById("status").readOnly = false;
                      document.getElementById("status").value = 'Bitte wählen Sie mindestens ein Produkt aus!'
                      document.getElementById("status").readOnly = true;
                    }
                  }                  
              `}
                  </script>
                </Helmet>
              </div>
            </div>
            <Contacts />
            <div className="clearer">
              <span />
            </div>
          </div>
          <Footer />
        </div>
      </div>
    )
  }
}

export default Bestellungen
